import { Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { PortalLayoutComponent } from './layouts/portal-layout/portal-layout.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { authGuard } from './guards/auth.guard';
import { RolesComponent } from './pages/roles/roles.component';
import { UsersComponent } from './pages/users/users.component';

export const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full'
    },
    {
        path: 'login',
        component: LoginComponent,
        data: { title: 'Login' },
    },
    {
        // path: 'create-account',
        path: 'sign-up',
        component: SignUpComponent,
        data: { title: 'Sign Up' },
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: { title: 'Forgot Password' }
    },
    {
        path: '',
        component: PortalLayoutComponent,
        canActivateChild: [authGuard],
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent,
                data: { title: 'Dashboard' }
            },
            {
                path: 'roles',
                component: RolesComponent,
                data: { title: 'Roles' }
            },
            {
                path: 'users',
                component: UsersComponent,
                data: { title: 'Users' }
            },
            {
                path: 'profile',
                component: ProfileComponent,
                data: { title: 'Profile' }
            },
            {
                path: 'settings',
                component: SettingsComponent,
                data: { title: 'Settings' }
            }
        ]
    },
    {
        path: '**',
        component: PageNotFoundComponent,
        data: { title: 'Page Not Found' },
    }
];
