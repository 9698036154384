<div class="row">
    <div class="col-12 col-md">
        <div class="row">
            <div class="col">
                <!-- Date time card -->
                <div class="card bg-white">
                    <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="d-flex justify-content-center align-items-center bg-dark rounded p-3 px-4">
                                <i class="fi fi-rs-calendar fs-3 text-white"></i>
                            </div>
                            <div class="text-end">
                                <h3 class="mb-0 text-dark">{{ currentDate | date: 'dd-MM-YYYY' }}</h3>
                                <p class="mb-0 text-secondary">Date</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
